import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Answer } from 'src/app/models/answer';
import { Question } from 'src/app/models/question';
import { ViewImageDialog } from 'src/app/modules/admin/missions/dialogs/view-image-dialog/view-image-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { FileServer } from 'src/app/services/fileserver.service';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { environment } from 'src/environments/environment';

interface WholeAnswer {
  question: string;
  question_id: number;
  answers: any;
  type: string;
  date: string;
  geolocation: {
    lat: any,
    lng: any
  }
}


@Component({
  selector: 'answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss']
})

export class AnswersComponent implements OnInit {

  @Input() answer_by: string = null;
  @Input() questions: Question[] = [];
  @Input() taskLocation: Location = null;
  public answers: Answer[] = [];
  public wholeAnswer: WholeAnswer[] = [];
  public nr_questions: number = 0;
  mapUrl: string = null;

  constructor(
    public dataService: DataService,
    public geoloc: GeolocationService,
    public fs: FileServer,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if(this.questions){
      this.createWholeAnswer(this.questions);
    }
    this.mapUrl = environment.gmaps.maps_place;
  }

  public createWholeAnswer(questions: Question[]) {
    this.nr_questions = questions.length;
    questions.forEach((item, i) =>{
      if(item.answer){
        this.wholeAnswer.push({  
          question: item.title,
          question_id: item.id,
          answers: [],
          type: item.type,
          date: moment(item.answer.created_at).format('YYYY-MM-DD HH:mm'),
          geolocation: {
            lat: Number(item.answer.result.lat).toFixed(6),
            lng: Number(item.answer.result.lng).toFixed(6)
          }
        })

        if (item.config?.length && item?.type != "comment") {
          item.config.forEach(element => {
            if (element['value'] && item.answer.result.value?.includes(element['value'])) {
              this.wholeAnswer[i].answers.push(element['label']);
            }
          });
        } else {
          this.wholeAnswer[i].answers.push(item.answer.result.value);
        }
      }
    })
  }

  viewImage(path:string){
    let image = this.fs.getImage(path)
    const dialogRef = this.dialog.open(ViewImageDialog, {
      data: {
        img: image
      },
      autoFocus: false,
      height: '90%',
      width: '100vw',
      maxHeight: '90vh',
      panelClass: 'full-screen-modal',
    });
  }
  
  
}

