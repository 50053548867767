import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { DataService, ListApi } from 'src/app/services/data.service';
import { VeriftooService } from 'src/app/services/veriftoo.service';
import { environment } from 'src/environments/environment';

export interface TableColumn {
  column: string; // object property
  db_field: string; // database field
  label: string; // label
  formatter?: (v: any, mainObject: any) => any; // formatter
  type?: string; // type = number|string|boolean|html|date|menu|server_image|text
  menuName?: string; // if type = menu, give the name of the menu for this.getMenu(menuName)
  width?: number; // column width
  visible?: boolean; // show column in the table
  sortable?: boolean; // show sort
  expandable?: boolean; // add column content in expand
  filter?: boolean; // show filter input
  filterFormatter?(v): any; // filter formatter
  filterField?:any; // info about the filter field, default is text field 
  sticky?: string | boolean; // sticky column = true|'start'|'end'
  cssClass?: string; // css class for the column
  disableVisibility?: boolean; // disable column in visibility menu
  tooltip?: string;
  icon?: string;
}

const defaultTableColumn: TableColumn = {
  column: null,
  db_field: null,
  label: null,
  formatter: (v) => {
    return v;
  },
  type: 'string',
  menuName: null,
  width: 200,
  visible: true,
  sortable: true,
  expandable: false,
  filter: true,
  filterFormatter: (v) => {
    return v;
  },
  sticky: false,
  cssClass: '',
  disableVisibility: false,
  filterField: []
};

export interface TableRow {
  cssClass?: (mainObject: any) => string;
}

@Component({
  selector: 'mat-table-api',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent implements OnInit {
  public ready = false;
  public data: any = [];
  public params = {};
  public resultsLength = 0;
  public isLoadingResults = true;
  public isRateLimitReached = false;
  public displayedDbFields = [];
  public dbFiltrableFields = [];
  // public displayedColumns = [];
  public expandedElement = null;
  public expandedElements = [];
  public filteredColumns = [];
  public expandableColumns = [];
  public currentExpandedContent = null;
  public expandedContents = [];
  public allColumnExpanded: boolean = false;
  public s_visibility = {};
  public s_pageSize = null;
  public storageColFilters = {};
  public storageColSort = {};
  public removableStorageColFilters = [];
  public initialSortCol = null;
  public initialSortDir = null;
  public colTypes = {
    number: {
      defaultWidth: 100,
    },
    boolean: {
      defaultWidth: 100,
    },
    string: {
      defaultWidth: 200,
    },
    html: {
      defaultWidth: 200,
    },
    date: {
      defaultWidth: 100,
    },
    menu: {
      defaultWidth: 60,
    },
    budget_md: {
      defaultWidth: 100,
    },
    budget_pct: {
      defaultWidth: 100,
    },
  };
  boolean_labels = {
    true: null,
    false: null,
  };

  paginator_pageSize = 100;

  filter = '';
  filter_db_fields = {};
  filter_db_fields_values = {};
  global_filter_value = '';
  _filterTimeout = null;
  tableColumns: TableColumn[] = [];
  tableRow: TableRow = {};
  filters = [];
  _excludedFromGlobalSearch = ['actions'];
  protected _menus = [];
  public touchedColumns = [];
  public currentColVisibility = [];

  @ViewChild('paginator1', { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable) updateSticky: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() table = null;
  @Input() endpoint = null;
  @Input() set columnsDef(columns: TableColumn[]) {
    const cols = [];
    columns.forEach((c) => {
      const d = Object.assign({}, defaultTableColumn);
      const o = Object.assign(d, c);
      if (!c.width && this.colTypes[o.type]) {
        o.width = this.colTypes[o.type].defaultWidth;
      }
      if (o.type == 'boolean' && !c.formatter) {
        o.formatter = (v) => {
          return v ? this.boolean_labels.true : this.boolean_labels.false;
        };
      }
      if (o.type == 'date' && !c.formatter) {
        o.formatter = (v) => {
          return v ? moment(v).format('YYYY-MM-DD') : '';
        };
      }

      if (o.type == 'budget_md' && !c.formatter) {
        o.formatter = (v) => {
          return v ? Math.round((v + Number.EPSILON) * 10) / 10 : '0.0'; // v ? v.toFixed(1) : '0.0';
        };
      }
      if (o.type == 'budget_pct' && !c.formatter) {
        o.formatter = (v) => {
          return v ? Math.round((v + Number.EPSILON) * 10) / 10 : '0.0'; //v ? v.toFixed(1) : '0.0';
        };
      }

      if (o.type == 'server_image' && !c.formatter) {
        o.formatter = (v) => {
          return v ? this.location.normalize(this.fs_images + v) : null;
        };
      }

      // filterFormatter for number type
      if (o.type == 'number' && !c.filterFormatter) {
        o.filterFormatter = (v) => {
          if (v != '') {
            switch (v) {
              case '^$':
                break;
              case '$^':
                break;
              default:
                let first = v.slice(0, 1);
                let last = v.slice(-1);

                // return exact value(use '=' instead 'like')
                if (first != '^' && last != '$') {
                  v = '^' + v + '$';
                }
                break;
            }
          }
          return v;
        };
      }

      cols.push(o);
    });
    this.tableColumns = cols;
  }
  @Input() set rowDef(row: TableRow) {
    this.tableRow = row;
  }

  @Input() enableResetColumns = true;
  @Input() withFilterField = true;
  @Input() withColumnFilters = true;
  @Input() withColumnVisibility = true;
  @Input() expandable = false;
  @Input() expandIcon = false;
  @Input() expandableTemplate = null;
  @Input() sortActiveColumn = null;
  @Input() sortActiveColumnDirection: SortDirection = 'asc';
  @Input() excludedFromGlobalSearch = [];
  @Input() filterByParam = {};
  @Input() set menus(m) {
    this._menus = m;

    if(this.enableResetColumns && this.withColumnVisibility) {

      let isPresent = this._menus['topMenu'].some(function(el){return el.icon === "grid_on"});

      if(!isPresent){
        this._menus['topMenu'].push(
          { icon: 'grid_on', label: this.translate.instant('BUTTONS.BT_RESET_COLUMN_VISIBILITY'), click: () => {this.resetColumnVisibility()}}
        )
      }
    }
  }; 

  @Input() Model = null;

  @Input() set extraParameters(p) {
    this._extraParameters = p;
  }
  private _extraParameters = {};

  @Input() rememberFilters = true;
  @Input() storageFiltersTimeout = 60; // minutes

  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() expand: EventEmitter<any> = new EventEmitter();
  @Output() menuEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterMapData: EventEmitter<any> = new EventEmitter();

  @Output() rowDetail: EventEmitter<any> = new EventEmitter();
  protected fs_images = environment.fileserver.images;

  constructor(
    public auth: AuthenticationService,
    protected dataService: DataService,
    protected translate: TranslateService,
    protected veriftooService: VeriftooService,
    private sanitizer: DomSanitizer,
    private router: Router,
    protected location: Location
  ) {
    this.boolean_labels.true = this.translate.instant('TABLE.YES');
    this.boolean_labels.false = this.translate.instant('TABLE.NO');
  }

  ngOnInit() {
    this.initialSortCol = this.sortActiveColumn
      ? this.sortActiveColumn
      : this.displayedDbFields[0];
    this.initialSortDir = this.sortActiveColumnDirection;
    this.preperVars();
    this._excludedFromGlobalSearch = this._excludedFromGlobalSearch.concat(
      this.excludedFromGlobalSearch
    );
  }

  // allow styling in innerHTML
  AllowStylingInnerHTML(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    let t1 = 0;
    let t2 = 0;

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          let excluded_cols = this._excludedFromGlobalSearch;
          let dbVisibleFields = this.displayedDbFields.filter((el) => {
            return !excluded_cols.includes(el);
          });

          t1 = new Date().getTime();
          this.isLoadingResults = true;
          this.params = {
            sort: this.sort.active,
            dir: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            per_page: this.paginator.pageSize,
            visible_columns: JSON.stringify(dbVisibleFields),
          };
          if (this._extraParameters) {
            this.params = Object.assign({}, this.params, this._extraParameters);
          }

          // remember col sort
          this.rememberColSort();

          this.filters = [];
          // global filter
          if (this.filter.length) {
            let filtered_columns = {};
            this.dbFiltrableFields.forEach((col) => {
              if (!excluded_cols.includes(col)) {
                let o = _.find(this.tableColumns, { db_field: col });
                filtered_columns[col] = o.filterFormatter(this.filter);
              }
            });
            this.filters.push(this.getFilters(filtered_columns, 'OR'));

            // remember global filter
            if (this.rememberFilters) {
              this.rememberColFilters('global_filter', this.filter);
            }
          } else {
            // clear global filter
            if (this.rememberFilters) {
              this.clearStorageColFilters('global_filter');
            }
          }

          // filterByParam
          if (this.objectSize(this.filterByParam) > 0) {
            for (let p in this.filterByParam) {
              this.filter_db_fields[p] = this.filterByParam[p];
            }
          }
          this.filterByParam = {};

          // column filter
          if (
            this.rememberFilters &&
            this.objectSize(this.storageColFilters) > 0
          ) {
            for (let ff in this.storageColFilters) {
              let c = _.find(this.tableColumns, { db_field: ff });
              if (c) {
                this.applyColFilter(this.storageColFilters[ff], c);
              }
            }
          }
          if (this.objectSize(this.filter_db_fields) > 0) {
            // remove hidden fields from filters
            let visible_filter_db_fields = {};
            for (let ff in this.filter_db_fields) {
              if (this.displayedDbFields.includes(ff)) {
                visible_filter_db_fields[ff] = this.filter_db_fields[ff];
              }
            }
            this.filter_db_fields = visible_filter_db_fields;

            this.filters.push(this.getFilters(this.filter_db_fields, 'ANDD'));
          } else {
            // clear cols filters
            if (this.rememberFilters) {
              this.clearStorageColFilters(null, 'col_filter');
            }
          }

          if (this.filters.length) {
            this.params['filter'] = JSON.stringify(this.filters);
          }

          return this.dataService.getList(this.endpoint, this.params);
        }),
        map((data: ListApi) => {
          // Flip flag to show that loading has finished.
          t2 = new Date().getTime();
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          if (data && data['_debugbar'] && data['_debugbar'].time) {
            // console.log('debugbar', data['_debugbar'].time.duration_str);
          }

          this.afterMapData.emit(data);
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has an error. Return empty data.
          this.isRateLimitReached = true;
          return of([]);
        })
      )
      .subscribe((data) => {
        if (this.Model) {
          this.data = [];
          data.forEach((u) => {
            let el = u;
            el = new this.Model(u);
            this.data.push(el);
          });
        } else {
          this.data = data;
        }
        this.unExpandAll();
      });
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  preperVars() {
    let storage_data = this.getStorageData();
    let table_storage_data = storage_data[this.table];

    // remove filters from storage
    if (this.rememberFilters && storage_data) {
      // if (this.rememberFilters && table_storage_data['columns_filters']) {
      if (storage_data['filters_timeout']) {
        const unixTimestamp = storage_data['filters_timeout'];
        const milliseconds = storage_data['filters_timeout'] * 1000;
        const dateObject = new Date(milliseconds);

        let futureDate = new Date(
          dateObject.getTime() + this.storageFiltersTimeout * 60000
        );
        let nowDate = new Date();
        let nowTimeout = this.toTimestamp(nowDate);

        if (nowTimeout > this.toTimestamp(futureDate)) {
          this.clearStorage();
          // this.clearStorageColFilters();
          // this.clearColSort();
          this.removableStorageColFilters = [];
        }
      }
    }

    if (table_storage_data) {
      this.s_visibility = table_storage_data['columns_visibility'];
      this.s_pageSize = table_storage_data['pageSize'];
      this.storageColFilters = table_storage_data['columns_filters'];
      this.storageColSort = table_storage_data['sort'];
    }

    this.rememberStorageTimeout();

    this.setTableColumns();

    if (this.s_pageSize) {
      this.paginator_pageSize = this.s_pageSize;
    }
  }

  isTableColumnVisible(element) {
    let visivility = element.visible;
    if (
      this.withColumnVisibility &&
      this.s_visibility &&
      this.s_visibility.hasOwnProperty(element.column)
    ) {
      visivility = this.s_visibility[element.column];
    }
    return visivility;
  }

  setTableColumns() {
    // displayedDbFields
    this.displayedDbFields = [];
    if (this.expandIcon) {
      this.displayedDbFields.push('expand-icon');
    }
    this.currentColVisibility = [];
    this.tableColumns.forEach((element) => {
      // if (this.s_visibility && this.s_visibility.hasOwnProperty(element.column)) {
      //   element.visible = this.s_visibility[element.column];
      // }

      let visivility = element.visible;
      if (
        this.withColumnVisibility &&
        this.s_visibility &&
        this.s_visibility.hasOwnProperty(element.column)
      ) {
        visivility = this.s_visibility[element.column];
      }

      this.currentColVisibility[element.column] = visivility;
      // if (this.isTableColumnVisible(element)) {
      if (visivility) {
        this.displayedDbFields.push(element.db_field);
        if (element.filter) {
          this.dbFiltrableFields.push(element.db_field);
        }
      } else if (element.expandable && element.filter) {
        this.dbFiltrableFields.push(element.db_field);
      }

      // filterByParam
      if (this.objectSize(this.filterByParam) > 0) {
        for (let p in this.filterByParam) {
          this.filter_db_fields_values[p] = this.filterByParam[p];
        }
      }

      // storage col filters
      if (this.rememberFilters && this.objectSize(this.storageColFilters) > 0) {
        for (let f in this.storageColFilters) {
          this.filter_db_fields_values[f] = this.storageColFilters[f];
        }
      }

      if (!this.filter_db_fields_values) {
        this.filter_db_fields_values[element.db_field] = '';
      }

      // expandableColumns
      if (element.expandable) {
        this.expandableColumns.push({
          column: element.column,
          db_field: element.db_field,
        });
      }
    });

    // storage global filter
    if (
      this.rememberFilters &&
      this.objectSize(this.storageColFilters) > 0 &&
      typeof this.storageColFilters['global_filter'] !== 'undefined'
    ) {
      this.global_filter_value = this.storageColFilters['global_filter'];
      this.filter = this.storageColFilters['global_filter'];
    }

    // filteredColumns
    this.filteredColumns = [];
    if (this.withColumnFilters) {
      this.displayedDbFields.forEach((element) => {
        this.filteredColumns.push(element + '_filter');
      });
    }

    // sortActiveColumn
    if (this.storageColSort && this.objectSize(this.storageColSort) > 0) {
      this.sortActiveColumn = this.storageColSort['column']
        ? this.storageColSort['column']
        : this.sortActiveColumn;
      this.sortActiveColumnDirection = this.storageColSort['dir']
        ? this.storageColSort['dir']
        : this.sortActiveColumnDirection;
    } else if (!this.sortActiveColumn) {
      this.sortActiveColumn = this.displayedDbFields[0];
    }

    // update sticky column styles
    setTimeout(() => {
      if(this.updateSticky){
          this.updateSticky.updateStickyColumnStyles();
        }
    }, 500);
  }

  isSticky(column: TableColumn, sticky_mode: string) {
    if (sticky_mode == 'start') {
      if (
        (column.sticky && column.sticky == 'start') ||
        column.sticky === true
      ) {
        return true;
      }
    }

    if (sticky_mode == 'end') {
      if (column.sticky && column.sticky == 'end') {
        return true;
      }
    }

    return false;
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase();
    if (this._filterTimeout) {
      clearTimeout(this._filterTimeout);
    }
    // if (this.filter.length >= 2 || this.filter.length == 0) {
    this._filterTimeout = setTimeout(() => {
      this.refresh();
    }, 500);
    // }
  }

  applyColFilter(filterValue: string, column: TableColumn, event = null) {
    let db_field = column.db_field;
    filterValue = column.filterFormatter(filterValue);

    if (typeof filterValue != 'boolean') {
      // if (filterValue.length >= 2 || filterValue.length == 0) {
      this.filter_db_fields[db_field] = filterValue.trim().toLowerCase();
      // }
    } else {
      this.filter_db_fields[db_field] = filterValue ? 1 : 0;
    }

    let filter_fields = {};
    for (let ff in this.filter_db_fields) {
      if (this.filter_db_fields[ff] !== '') {
        filter_fields[ff] = this.filter_db_fields[ff];
        if (this.rememberFilters) {
          let indexOf = this.removableStorageColFilters.indexOf(ff);
          if (indexOf > -1) {
            this.removableStorageColFilters.splice(indexOf, 1);
          }
        }
      } else {
        if (this.rememberFilters) {
          let indexOf = this.removableStorageColFilters.indexOf(ff);
          if (indexOf === -1) {
            this.removableStorageColFilters.push(ff);
          }
        }
      }
    }
    this.filter_db_fields = filter_fields;
    if (this._filterTimeout) {
      clearTimeout(this._filterTimeout);
    }
    if (typeof filterValue == 'boolean') {
      this._filterTimeout = setTimeout(() => {
        // this.onColFilter();
      }, 500);
    }
  }

  onColFilter() {
    // remove col filters
    if (this.rememberFilters) {
      for (let fcv in this.filter_db_fields_values) {
        if (this.filter_db_fields_values[fcv] !== '') {
          this.rememberColFilters(fcv, this.filter_db_fields_values[fcv]);
        } else {
          this.clearStorageColFilters(fcv);
        }
      }

      this.removableStorageColFilters.forEach((f) => {
        this.clearStorageColFilters(f);
      });
      this.removableStorageColFilters = [];
    }
    this.refresh();
  }

  onResetFilters() {
    // reset global filter
    this.filter = '';
    this.global_filter_value = '';

    // reset column filters
    this.filter_db_fields = {};
    for (let fcv in this.filter_db_fields_values) {
      this.filter_db_fields_values[fcv] = '';
    }

    // clear storage col filters
    if (this.rememberFilters) {
      this.removableStorageColFilters = [];
      this.clearStorageColFilters();
    }

    setTimeout(() => {
      // reset sort
      this.resetSort();
      this.refresh();
    });
  }

  resetSort() {
    this.clearColSort();
    this.sort.sort({
      id: this.initialSortCol,
      start: 'asc',
      disableClear: false,
    });
    this.sort.direction = this.initialSortDir;
  }

  refresh() {
    this.paginator._changePageSize(this.paginator.pageSize);

    // the table it was refreshed
    this.onRefresh.emit(true);
  }

  onRowDetail(row = null){
    if(row){
      this.rowDetail.emit(row);
    }
  }

  onExpand(item = null) {
    if (this.expandable) {
      if (!item) {
        this.expandAll();
      } else {
        this.expandRow(item);
      }
    }
  }

  expandTemplate(item) {
    let self = this;
    let template = '';

    let expandableColumns = null;
    if (this.expandableColumns) {
      expandableColumns = this.expandableColumns;
    }

    let columnType = 'html';

    if (this.expandableTemplate) {
      template += this.expandableTemplate;

      template = template.replace(/{{\w+}}/g, function (variable) {
        let column = variable.split('{{').pop().split('}}')[0];
        column = column.replace(/\s/g, '');

        // visibility
        let visibility = column.substr(0, column.indexOf('_'));
        var col = column.substring(column.indexOf('_') + 1);
        if (visibility === 'visibilityclass') {
          column = col;
        }

        let c = _.find(self.tableColumns, { column: column });
        if (c) {
          columnType = c.type;
        }

        let columnContent = '';
        if (expandableColumns) {
          let o = _.find(expandableColumns, { column: column });
          if (o) {
            if (c) {
              columnContent = item[column]
                ? c.formatter(item[column], item)
                : '';
            } else {
              columnContent = item[column] || '';
            }
          }
        }

        // return columnContent a class name for visible/not visible
        if (visibility === 'visibilityclass') {
          if (columnContent == '' || columnContent == null) {
            columnContent = 'no-content';
          } else {
            columnContent = 'with-content';
          }
        }

        return columnContent;
      });
    } else {
      if (expandableColumns) {
        expandableColumns.forEach((col) => {
          let column_content = this.getProperty(col.column, item);
          template += '<div>' + col.column + ' = ' + column_content + '</div>';
        });
      }
    }

    return {
      content: template,
      column_type: columnType,
    };
  }

  expandRow(row) {
    let id = row.id;
    let expand = false;
    if (this.expandedElements.indexOf(id) > -1) {
      expand = false;
      let index = this.expandedElements.indexOf(id);
      this.expandedElements.splice(index, 1);
    } else {
      expand = true;
      this.expandedElements.push(id);

      let item = row;
      let template = this.expandTemplate(item);
      let expanded_item = {
        item_id: item.id,
        column_type: template.column_type,
        content: template.content,
      };

      this.currentExpandedContent = expanded_item;
      this.expandedContents[item.id] = expanded_item;
    }

    // this.expand.emit(event);
  }

  expandAll() {
    this.expandedElements = [];
    if (this.data.length) {
      this.data.forEach((element) => {
        // this.expandedElements.push(element.id);
        this.expandRow(element);
      });
      if (this.allColumnExpanded) {
        this.expandedElements = [];
        this.allColumnExpanded = false;
        // this.expand.emit({ expand: true, items: this.data })
      } else {
        this.allColumnExpanded = true;
        // this.expand.emit({ expand: true, items: this.data })
      }
    }
  }

  unExpandAll() {
    this.allColumnExpanded = false;
    this.expandedElements = [];
  }

  isColumnVisible(db_field: string) {
    if (this.displayedDbFields.indexOf(db_field) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  getStorageData() {
    try {
      let val = localStorage.getItem('storage');
      if (val) {
        return JSON.parse(val);
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  }

  setColumnVisibility(event, table_column: TableColumn) {
    if (this.withColumnVisibility) {
      let index = _.findIndex(this.touchedColumns, {
        column: table_column.column,
      });
      if (index !== -1) {
        this.touchedColumns[index] = {
          visibility: event.checked,
          column: table_column.column,
        };
      } else {
        this.touchedColumns.push({
          visibility: event.checked,
          column: table_column.column,
        });
      }
    }
  }

  applyColVisibility() {
    if (this.touchedColumns.length) {
      this.rememberColumnVisibility(this.touchedColumns);

      // set columns
      this.setTableColumns();
    }
  }

  rememberColumnVisibility(columns) {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        columns_visibility: {},
      };
    } else {
      if (!data[this.table]['columns_visibility']) {
        data[this.table]['columns_visibility'] = {};
      }
    }
    if (columns) {
      columns.forEach((c) => {
        data[this.table]['columns_visibility'][c.column] = c.visibility;
      });
    }
    this.touchedColumns = [];
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.s_visibility = data[this.table]['columns_visibility'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberPageSize() {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        pageSize: this.paginator.pageSize,
      };
    } else {
      if (!data[this.table]['pageSize']) {
        data[this.table]['pageSize'] = '';
      }
      data[this.table]['pageSize'] = this.paginator.pageSize;
    }

    try {
      localStorage.setItem('storage', JSON.stringify(data));
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  getProperty(
    propertyName: string | Array<string>,
    object = null,
    parent = null
  ) {
    if (!object) {
      return null;
    }
    let parts =
      typeof propertyName == 'string' ? propertyName.split('.') : propertyName;
    let first = parts.shift();
    if (parts.length > 0) {
      return this.getProperty(parts, object[first]);
    } else {
      return object[first];
    }
  }

  findById(array, id) {
    let o = _.find(array, { item_id: id });
    if (o) {
      return o;
    } else {
      return null;
    }
  }

  objectSize(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  returnValue(v) {
    if (typeof v === 'undefined') {
      return false;
    } else {
      return true;
    }
  }

  menuItemEvent(event, row = null) {
    let retEvent = null;

    if (row) {
      retEvent = { event: event, item: row };
    } else {
      retEvent = {
        event: event,
        filters: this.filters,
        params: this.params,
        extraParameters: this._extraParameters,
      };
    }

    this.menuEvent.emit(retEvent);
  }

  getMenu(menuName: string) {
    let menu = this._menus[menuName];
    if (menu) {
      return menu;
    } else {
      return [];
    }
  }

  toBoolean(v) {
    if (v == 1) {
      return true;
    } else if (v == 0) {
      return false;
    } else {
      return '';
    }
  }

  resetColumnVisibility() {
    // remove data from storage
    this.s_visibility = {};
    let data = this.getStorageData();
    if (!data[this.table]) {
      data[this.table] = {
        columns_visibility: {},
      };
    } else {
      data[this.table]['columns_visibility'] = {};
    }
    try {
      localStorage.setItem('storage', JSON.stringify(data));
    } catch (e) {
      console.log('Error saving localstorage');
    }

    // reset columns
    this.setTableColumns();
  }

  getFilters(fields, operator) {
    let filter = {
      operator: operator,
      fields: fields,
    };
    return filter;
  }

  rememberColFilters(column, value) {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        columns_filters: {},
      };
    } else {
      if (!data[this.table]['columns_filters']) {
        data[this.table]['columns_filters'] = {};
      }
    }
    data[this.table]['columns_filters'][column] = value;
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColFilters = data[this.table]['columns_filters'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearStorageColFilters(column = null, type = null) {
    let data = this.getStorageData();
    let storageColFilters = this.storageColFilters;
    if (data[this.table]) {
      if (data[this.table]['columns_filters']) {
        if (column) {
          if (
            typeof data[this.table]['columns_filters'][column] !== 'undefined'
          ) {
            delete data[this.table]['columns_filters'][column];
          }
        } else {
          if (type) {
            if (type === 'global_filter') {
              delete data[this.table]['columns_filters']['global_filter'];
            } else if (type === 'col_filter') {
              for (let cf in data[this.table]['columns_filters']) {
                if (cf !== 'global_filter') {
                  delete data[this.table]['columns_filters'][cf];
                }
              }
            }
          } else {
            delete data[this.table]['columns_filters'];
          }
        }
        storageColFilters = data[this.table]['columns_filters'];
      }
    }

    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColFilters = storageColFilters;
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberColSort() {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        sort: {},
      };
    } else {
      if (!data[this.table]['sort']) {
        data[this.table]['sort'] = {};
      }
    }
    data[this.table]['sort']['column'] = this.sort.active;
    data[this.table]['sort']['dir'] = this.sort.direction;

    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSort = data[this.table]['sort'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearColSort() {
    let data = this.getStorageData();

    if (data[this.table]) {
      delete data[this.table]['sort'];
    }
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSort = {};
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearStorage() {
    let data = {};
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSort = {};
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberStorageTimeout() {
    let data = this.getStorageData();
    data['filters_timeout'] = this.toTimestamp(new Date());

    try {
      localStorage.setItem('storage', JSON.stringify(data));
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  trackByIndex(i) {
    return i;
  }

  selectionChange(filterValue: string, column: TableColumn, event = null) {
    this.applyColFilter(filterValue, column, event);
    setTimeout(() => {
      this.onColFilter();
    })
  }
}
