import { environment } from './../../../../../environments/sample.environment';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TaskUser } from 'src/app/models/taskUser';
import { DataService } from 'src/app/services/data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskUserStatus } from 'src/app/models/enum-task-user-status';
import { ConfirmDialog } from 'src/app/components/dialogs/confirmdialog/confirm-dialog.component';
import { Location as Loc } from '@angular/common' ;
import { Location } from 'src/app/models/location';
import { ViewImageDialog } from '../../missions/dialogs/view-image-dialog/view-image-dialog.component';
import { FileServer } from 'src/app/services/fileserver.service';

@Component({
  selector: 'completed-task-dialog',
  templateUrl: './completed-task-dialog.component.html',
  styleUrls: ['./completed-task-dialog.component.scss']
})
export class CompletedTaskDialogComponent implements OnInit {

  public ready: boolean = false;
  public loader: boolean = false;
  public reject_from: boolean = false;
  public save_reject_loader = false;
  public taskUser: TaskUser = null;
  public selectedTab: number = 1;
  public missionTitle: string = null;
  public taskTitle: string = null;
  public btn_show: boolean = true;
  public show_btn_paid: boolean = false;
  public actions_btn_show: boolean = false;;
  public taskUserStatus = TaskUserStatus;
  public form: FormGroup;
  public defaultReason: string = null;
  public addDays: {value: number, viewValue: string}[] = null;
  public fs_images=environment.fileserver.images;
  public issue_photo = null;
  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }],
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<CompletedTaskDialogComponent>,
    private router: Router,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private location:Loc,
    public fs: FileServer,
    @Inject(MAT_DIALOG_DATA) public data, private dataService: DataService) {
    this.defaultReason = this.translate.instant('COMPLETED_TASKS_USER.DIALOG.DEFAULT_REASON');

    this.addDays = [
      { value: 2, viewValue: this.translate.instant('COMPLETED_TASKS_USER.DIALOG.ADD2_DAYS') },
      { value: 1, viewValue: this.translate.instant('COMPLETED_TASKS_USER.DIALOG.ADD1_DAY') },
      { value: 0, viewValue: this.translate.instant('COMPLETED_TASKS_USER.DIALOG.CANCEL_MISSION') },
    ];
  }

  ngOnInit() {
    let proms = [];
    if (this.data) {
      if (this.data.item && this.data.item.id) {
        proms.push(
          this.dataService.getAsPromise(`admin/task_user/${this.data.item.id}`)
            .then(res => {
              if (res && res.data) {
                this.taskUser = new TaskUser(res.data);
    
                if(this.taskUser.status == this.taskUserStatus.Completed || this.taskUser.status == this.taskUserStatus.Disputed || this.taskUser.status == this.taskUserStatus.Issue){
                  this.actions_btn_show = true;
                }
                if(this.taskUser.status == this.taskUserStatus.Finalized){
                  this.show_btn_paid = true;
                }
              }
            })
        )
      }
    }
    Promise.all(proms).then(rez => {
      this.missionTitle = this.taskUser.task.mission?.title ? this.translate.instant('COMPLETED_TASKS_USER.DIALOG.MISSION_TITLE', { mission: this.taskUser.task.mission.title }) : '';
      this.taskTitle = this.taskUser.task?.title ? this.translate.instant('COMPLETED_TASKS_USER.DIALOG.TASK_TITLE', { task: this.taskUser.task.title }) : '';
      this.ready = true;
    }).catch(err => {
      console.log(err);
    })

  }


  goToMission(id: number) {
    if (id) {
      this.router.navigate([`missions/${id}`]);
      this.dialogRef.close();
    }
  }

  tabClick(tab) {
    if (tab.index && tab.index == 1) {
      this.btn_show = true;
    } else {
      this.btn_show = false;
    }
  }

  // allow styling in innerHTML
  AllowStylingInnerHTML(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  changeStatus(mode: string = null) {
    if (this.taskUser && this.taskUser.id) {
      this.loader = true;
      let data = {
        status: mode == 'paid' ? this.taskUserStatus.Paid : this.taskUserStatus.Finalized
      }
      this.dataService.putAsPromise(`admin/task_user/${this.taskUser.id}/change_status`, data).then(res => {
        if (res && res.mission_completed) {
            this.completedMission();
        }else{
           this.dialogRef.close('bt_save');
        }
        this.loader = false;
      }).catch(err => {
        this.loader = false;
      });
    }
  }


  completedMission() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('COMPLETED_TASKS_USER.DIALOG.COMPLETED_MISSION_TITLE'),
        text: this.translate.instant('COMPLETED_TASKS_USER.DIALOG.COMPLETED_MISSION_TEXT'),
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'bt_yes') {
        let missionId = this.taskUser.task.mission.id;
        let url = `admin/task_user/${missionId}/notify/completed_mission`; 
        this.dataService.getAsPromise(`admin/task_user/${missionId}/notify/completed_mission`)
        .then((res) => {
         console.log('res', res);
        }).catch((err) => {
            console.log('err = ', err);
        });
      }
      this.dialogRef.close('bt_save');
    });
  }


  createRejectForm(element: string = null) {
    this.reject_from = true;
    this.form = new FormGroup({
      reject_reason: new FormControl(this.defaultReason, [Validators.required]),
      status: new FormControl(this.taskUserStatus.Rejected, [Validators.required]),
      addDays: new FormControl('', [Validators.required])
    });
    this.scrollToElement(element);
  }

  changeOption(event: number) {
    if(event == 0){
      this.form.controls['status'].setValue(this.taskUserStatus.Canceled);
    }
  }

  saveReject() {
    this.form.markAllAsTouched();
    if (this.form.valid && this.taskUser.id) {
      this.save_reject_loader = true;
      let formData = this.form.value;
      this.dataService.putAsPromise(`admin/task_user/${this.taskUser.id}/change_status`, formData).then(res => {
        if (res && res.data) {
          this.dialogRef.close('all');
        }
        this.save_reject_loader = false;
      }).catch(err => {
        this.save_reject_loader = false;
      });
    } else {
      console.log("form not valid");
    }
  }

  getLocationTask(latitude: number, longitude: number): Location {
    let location = new Location();

    let task = {
      lat: latitude,
      lng: longitude
    };

    location.import(task);

    return location;
  }

  scrollToElement(element) {
    setTimeout(() => {
      let myElement = document.getElementsByClassName(element)[0] as HTMLElement;
      if (myElement) {
        myElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    }, 300);
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    let field = this.form.controls[controlName];
    return field.hasError(errorName) && field.touched;
  }

  viewImage(path:string){
    let image = this.fs.getImage(path)
    const dialogRef = this.dialog.open(ViewImageDialog, {
      data: {
        img: image
      },
      autoFocus: false,
      height: '90%',
      width: '100vw',
      maxHeight: '90vh',
      panelClass: 'full-screen-modal',
    });
  }

}
