export enum TaskUserStatus{
    Available = "available",
    Reserved = "reserved",
    Released = "released",
    In_progress = "in_progress",
    Completed = "completed",
    Rejected = "rejected",
    Canceled = 'canceled',
    Finalized = 'finalized',
    Expired = 'expired',
    Abandoned = "abandonned",
    Disputed = "disputed",
    Paid = "paid",
    Issue = "issue"
}
