import { Question } from './question';
import { Task } from './task';
import * as _ from 'lodash';
import { User } from './User';

interface Images {
  name: string;
  path: string;
};

export class Mission {
  public id: number = null;
  public user_id: number = null; // user id (owner of the mission)
  public mission_number: string = null;
  public title: string = null;
  public description: string = null;
  public status: string = null;
  public price: string = null;
  public manual_price: string = null;
  public payout: string = null;
  public type: string = null;
  public user_level: string = null;
  public reject_reason: string = null;
  public created_at: string = null;
  public start_date: string = null;
  public end_date: string = null;
  public accept_terms_at: string = null;
  public questions: Question[] = [];
  public questions_scenario: any = null;
  public tasks: Task[] = [];
  public pdf_invoice: string = null;
  public purchase_status: string = null;
  public images: Images[] = [];

  public user: User = null;


  constructor(id?: number | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  exists() {
    return this.id != null;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case 'questions':
            if (data[key]) {
              for (let i in data[key]) {
                // if question is already here we update
                console.log('questions', data[key][i]);
                const qid = _.findIndex(this.questions, { id: data[key][i].id });
                if (qid == -1) {
                  this.questions.push(new Question(data[key][i]));
                } else {
                  this.questions[qid] = new Question(data[key][i]);
                }
              }
            }
            break;
          case 'tasks':
            if (data[key]) {
              for (let i in data[key]) {
                // if task is already here we update
                const tid = _.findIndex(this.tasks, { id: data[key][i].id });
                if (tid == -1) {
                  this.tasks.push(new Task(data[key][i]));
                } else {
                  this.tasks[tid] = new Task(data[key][i]);
                }
              }
            }
            break;
          case 'user':
            this.user = new User(data[key]);
            break;
          default:
            this[key] = data[key];
        }
      }
    }
  }

  isPayable() {
    return this.questions && this.questions.length > 0
      && this.tasks && this.tasks.length > 0
      && (this.status == 'new' || this.status == 'rejected');
  }
}
