import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './../models/User';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Location } from '@angular/common';




@Injectable({
  providedIn: 'root'
})
export class FileServer {
  protected fs_images = environment.fileserver.images;
  protected fs_public = environment.fileserver.public;
  

  constructor(
    protected location: Location,
    private http: HttpClient
  ) { }

  getImage(path) {
    return this.location.normalize(this.fs_images + path);
  }
  
  getPublicImage(path) {
    return this.location.normalize(this.fs_public + path);
  }

  getAvatar(user: User | any, size = null) {
    if (!user.avatar) {
      return '';
    }
    let path = user.avatar;
    if (path) {
      if (size) {
        path = path.replace('/avatar.', `/${size}.`);
      }
      return this.location.normalize(this.fs_images + path);
    }
  }

  downloadFile(path, filename = null, mime = null) {

    if (!filename) {
      filename = path.split('/').pop()
    }

    return new Promise((resolve, reject) => {
      let headers = {};
      if (mime) {
        headers['Content-Type'] = mime;
      }
      this.http.get(this.getImage(path), {
        headers: new HttpHeaders(headers), responseType: 'blob'
      })
        .toPromise()
        .then(data => {
          const file = mime ? new Blob([data], { type: mime }) : new Blob([data]);

          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (filename) {
            element.download = filename;
          }
          document.body.appendChild(element);
          element.click();
          resolve(true);
        }).catch(error => {
          reject(error)
        });

    });
  }

  // downloadReport(path) {
  //   return new Promise((resolve, reject) => {
  //     let url = this.location.normalize(this.fs_radar + path)
  //     this.http.get(url,
  //       { responseType: 'blob', observe: 'response' })
  //       .subscribe(resp => {
  //         const filename = resp.headers.get('Content-Filename');
  //         const data = resp.body;
  //         const element = document.createElement('a');

  //         element.href = URL.createObjectURL(data);
  //         if (filename) {
  //           element.download = filename;
  //         }
  //         document.body.appendChild(element);
  //         element.click();
  //         resolve(true);
  //       });

  //   });
  // }


}
